@import "../../../../../styles/variables.scss";

.filter-dialog {
    display: grid;
    row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    width: 320px;
    height: 100%;
    overflow: hidden;

    @media screen and (max-width: 368px) {
        width: auto;
    }

    &__header {
        display: grid;
        gap: 16px;
    }

    &__search {
        width: 100%;
    }

    &__main {
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        grid-auto-rows: max-content;
    }

    &__footer {
        display: flex;
        gap: 16px;
    }
}
