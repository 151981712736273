.form {
    overflow: hidden;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: max-content auto;
    height: 100%;
    gap: 16px;
}

.form__header {
    display: flex;
    gap: 16px;
    align-items: center;
}

.form__content {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    gap: 16px;
    max-width: 650px;
    overflow: auto;
    scroll-behavior: smooth;
}
