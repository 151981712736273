.people-details {
    width: 100%;
    overflow: hidden;
}

.people-details__cols {
    display: flex;
    flex-wrap: nowrap;
    row-gap: 1px;
    gap: 8px;
    align-items: flex-end;
}

.people-details_small .people-details__cols {
    flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
    .people-details__cols {
        flex-wrap: wrap;
    }
}

.people-details__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: end;
    max-width: 40%;
    height: 100%;
    padding-bottom: 10px;
}

.people-details__info-btn {
    margin: 0 !important;
    padding: 0 !important;
    align-items: end !important;
    padding-bottom: 10px !important;
}

.people-details_small .people-details__info {
    width: calc(100% - 64px - 16px);
    max-width: calc(100% - 64px - 10px);
}

@media screen and (max-width: 600px) {
    .people-details__info {
        width: calc(100% - 64px - 16px);
        max-width: calc(100% - 64px - 10px);
    }
}

.people-details__description {
    margin-top: 8px;
}
