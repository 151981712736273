.tile {
    aspect-ratio: 1/1;
    overflow: hidden;
    width: 100%;
    position: relative;
    border: 1px solid transparent;
    z-index: 1;
    transition: transform 0.1s linear;
}

.tile:active {
    transform: scale(0.95);
}

.tile__checkbox {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    opacity: 0;
}

.tile_selected .tile__checkbox,
.tile:hover .tile__checkbox {
    opacity: 1;
}

.tile__container {
    padding: 12px 8px;
}
