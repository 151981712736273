.app-dialog__row-row {
    display: grid;
    row-gap: 4px;
}

.app-dialog__content {
    display: grid;
    row-gap: 12px;
    padding-bottom: 18px;
    padding-top: 18px;
    min-height: 50px;
}

.app-dialog__section {
    display: grid;
    grid-auto-flow: row;
    row-gap: 6px;
    grid-auto-rows: max-content;
}

.app-dialog__actions-btns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    -moz-column-gap: 8px;
         column-gap: 8px;
    width: 100%;
    justify-content: end;
}

.app-dialog__action-btn {
    width: -moz-max-content;
    width: max-content;
}
