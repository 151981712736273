.admin {
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    height: 100%;
    overflow: hidden;

    &__header {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: min-content;
        align-items: center;
        grid-gap: 8px;
        padding: 0 14px;
    }

    &__header-title {
        font-size: 18px;
        font-weight: 700;
    }

    &__header-icon {
        display: block;
        padding: 4px;
    }

    &__main {
        overflow: hidden;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: max-content auto;
        height: 100%;
    }

    &__loader {
        grid-row-start: 1;
        grid-row-end: 3;
    }

    &__container {
        padding: 24px 40px;
        overflow: auto;
        scroll-behavior: smooth;

        &:first-child {
            padding-top: 24px;
            @media screen and (max-width: 600px) {
                padding-top: 12px;
            }
        }

        &:last-child {
            padding-bottom: 24px;
            @media screen and (max-width: 600px) {
                padding-bottom: 12px;
            }
        }
    }

    &__title {
        margin-bottom: 24px;
    }

    &__form {
        display: grid;
        gap: 24px;
        max-width: 650px;
    }

    &__input-btn {
        display: grid;
        gap: 8px;
        grid-auto-flow: column;
        grid-template-columns: auto max-content;
    }
}
