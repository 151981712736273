.textarea-container {
    box-sizing: border-box;
    position: relative;
    padding: 0 0 var(--strokeWidthThick) 0;
    margin: 0;
    border-radius: var(--borderRadiusMedium);
    background-color: var(--colorNeutralBackground1);
    border: var(--strokeWidthThin) solid var(--colorNeutralStroke1);
    border-bottom-color: var(colorNeutralStrokeAccessible);

    &:hover {
        border: var(--strokeWidthThin) solid var(--colorNeutralStroke1Hover);
        border-bottom-color: var(--colorNeutralStrokeAccessibleHover);
    }

    &:active {
        border: var(--strokeWidthThin) solid var(--colorNeutralStroke1Pressed);
        border-bottom-color: var(--colorNeutralStrokeAccessiblePressed);
    }

    &:focus-within {
        border: var(--strokeWidthThin) solid var(--colorNeutralStroke1);
        border-bottom-color: var(--colorCompoundBrandStroke);
    }
}

.textarea {
    width: 100%;
    max-width: 270px;
    border-style: none;
    margin: 0;
    background-color: transparent;
    box-sizing: border-box;
    color: var(--colorNeutralForeground1);
    flex-grow: 1;
    font-family: var(--fontFamilyBase);
    height: 100%;
    outline-style: none;
    min-height: 52px;
    padding: var(--spacingVerticalSNudge)
        calc(var(--spacingHorizontalMNudge) + var(--spacingHorizontalXXS));
    max-height: 260px;

    &::-moz-placeholder {
        color: var(--colorNeutralForeground4);
        opacity: 1;
    }

    &::placeholder {
        color: var(--colorNeutralForeground4);
        opacity: 1;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: transparent;
    opacity: 0.5;
}

.highlight {
    background-color: yellow;
    opacity: 0.5;
}
