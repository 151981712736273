.upload-destination-container {
    display: grid;
    grid-template-rows: auto max-content;
    height: inherit;
    align-content: space-between;
}

.upload-destination-paths-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    width: 100%;
    overflow: auto;
}

.upload-destination-paths-container_breadcrumbs {
    display: grid;
    grid-template-rows: max-content auto;
    overflow: hidden;
}

.upload-destination-upload-btns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    -moz-column-gap: 10px;
         column-gap: 10px;
    height: 40px;
    align-items: center;
    align-content: center;
}

.upload-destination-upload-btn-label {
    display: grid;
    grid-auto-flow: column;
    align-items: start;
}

.upload-destination-path-btn {
    justify-content: start !important;
}

.upload-destination-container__loader {
    position: absolute;
}

.upload-destination-upload-switch > label {
    padding-left: 0 !important;
}
