$scaleIcon: 0.6;

.tile-icon {
    text-align: center;

    &__container {
        padding: 15px 8px 12px;
        width: 100%;
    }

    &__icon-container {
        height: 96px;
        width: 96px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 600px) {
            height: 96px * $scaleIcon;
            width: 96px * $scaleIcon;
        }
    }

    &__icon-container_bg {
        box-shadow:
            0 1.6px 3.6px rgba(0, 0, 0, 0.132),
            0 0.3px 0.9px rgba(0, 0, 0, 0.108);
        border-radius: 1px;
        background: linear-gradient(
            133.12deg,
            hsla(0, 0%, 100%, 0.0001) -1.71%,
            rgba(94, 94, 94, 0.06) 84.95%,
            hsla(0, 0%, 100%, 0.1) 99.06%
        );
    }

    &__icon {
        height: 87px;
        width: 87px;
        display: block;
        color: rgba(#605e5c, 0.64);

        @media screen and (max-width: 600px) {
            height: 87px * $scaleIcon;
            width: 87px * $scaleIcon;
        }
    }

    &__icon-container_bg &__icon {
        height: 64px;
        width: 64px;

        @media screen and (max-width: 600px) {
            height: 64px * $scaleIcon;
            width: 64px * $scaleIcon;
        }
    }

    &__link {
        max-width: 100%;
    }

    &__title {
        max-width: 100%;
        margin-top: 8px;
        text-decoration: none;
        display: inline-block;
    }

    &__link:hover &__title {
        text-decoration: underline;
    }
}
