.tag {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 16px;
    cursor: pointer;
    white-space: nowrap;
}
