.sharing-link-dialog__loader-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.sharing-link__email-option > span {
    display: none;
}
