@import "../../styles/variables.scss";

.app-dialog-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100vw;
    display: block;
    pointer-events: none;
    z-index: 100;

    &_open {
        pointer-events: initial;
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        display: block;
        opacity: 0;
        transition: opacity 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &_open &__overlay {
        opacity: 1;
    }

    &__container {
        position: fixed;
        min-width: 320px;
        height: calc(100dvh - $headerHeight);
        top: $headerHeight;
        right: -100%;
        padding: 20px 24px;
        transition: right 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);

        @media screen and (max-width: 600px) {
            min-width: auto;
        }
    }

    &_open &__container {
        right: 0;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 15px;
    }

    &__body {
        height: 100%;
        overflow: auto;
        position: relative;
    }
}
