.command-bar-container {
    z-index: 9;
    box-sizing: content-box;
    overflow: hidden;
    display: grid;
    align-content: center;
    padding-left: 8px;
    padding-right: 15px;
}

.command-bar__main-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
}

.command-bar__far-container {
    display: grid;
    grid-auto-flow: column;
    -moz-column-gap: 8px;
         column-gap: 8px;
}
