.dropzone {
    &-wrap {
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: var(--colorNeutralBackground1);
        z-index: 10;
        display: grid;
        align-items: center;
        justify-items: center;

        &_hidden {
            visibility: hidden;
        }
    }

    border-width: 6px;
    border-radius: 2px;
    border-color: var(--colorBrandBackground);
    border-style: dashed;
    background-color: var(--colorNeutralBackground1);
    outline: none;
    transition: border 0.24s ease-in-out;
    height: calc(100% - 30px);
    width: calc(100% - 20px);
    margin: 10px;
    display: grid;
    justify-content: center;
    align-content: center;
}
