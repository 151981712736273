.branding {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 650px;
}

.branding__color {
    width: 40px;
    height: 40px;
    display: block;
}

.brandig__row {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.branding__field {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
