.app-notifications {
    overflow-y: auto;
    height: inherit;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: max-content auto;
    row-gap: 14px;
}

.app-notifications__container {
    padding: 0 4px;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    row-gap: 14px;
}

.app-notifications__no-any {
    display: grid;
    justify-content: center;
    justify-items: center;
    row-gap: 4px;
    padding-top: 16px;
}

.app-notification__toolbar {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: space-between;
    -moz-column-gap: 4px;
         column-gap: 4px;
    width: 100%;
}

.app-notifications__select > select {
    background: none;
    border: none;
}
