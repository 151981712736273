.media-tooltip {
    display: grid;
    grid-auto-flow: dense;
    grid-auto-rows: max-content;
    row-gap: 4px;
    padding-top: 5px;
}

.media-tooltip__img {
    max-width: 260px;
    aspect-ratio: 16 / 9;
    width: 100%;
}

.thmbnl {
    cursor: zoom-in;
}
