.collection-loader {
    padding: 10px;
}

.collection-items {
    height: 200px;
    overflow: auto;
    padding-right: 4px;
    margin-right: -4px;
}

.collection-item {
    padding-top: 5.5px;
}

.btn-stretch > button {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: start;
}
