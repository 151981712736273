.extended-view-description__header {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: nowrap;
}

@media screen and (max-width: 600px) {
    .extended-view-description__header {
        flex-wrap: wrap;
    }
}
