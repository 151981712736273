.uploads {
    height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
}

.upload-card {
    display: grid;
    grid-auto-flow: row;
    row-gap: 8px;
    padding: 8px;
    border-radius: 6px;
    background-color: var(--colorNeutralBackground1);

    &:hover {
        box-shadow: var(--shadow2);
    }

    &__section {
        &_c {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: fit-content;
            justify-content: space-between;
            -moz-column-gap: 8px;
                 column-gap: 8px;

            &-center {
                align-items: center;
            }

            &-start {
                align-items: start;
            }
        }

        &_r {
            display: grid;
            grid-auto-flow: row;
            grid-auto-rows: max-content;
            row-gap: 2px;
        }
    }
}
