.notification-element {
    padding: 8px 12px;
}

.notification-element-message {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    -moz-column-gap: 8px;
         column-gap: 8px;
    grid-template-columns: max-content auto max-content;
}

@media screen and (max-width: 600px) {
    .notification-element-message {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        row-gap: 4px;
    }
}

.notification-element__icon {
    padding-top: 6px;
}

.notification-element__header {
    display: grid;
    grid-template-columns: 20px auto 32px;
    align-items: center;
    -moz-column-gap: 8px;
         column-gap: 8px;
    height: 32px;
}

.notification-element__close {
    height: 32px;
    width: 32px;
}

.notification-element__content {
    display: grid;
    grid-template-rows: max-content auto;
    row-gap: 8px;
    padding: 0 30px;
}

.notification-element__footer {
    display: grid;
    width: 100%;
    justify-content: end;
}
