* {
    box-sizing: border-box;
}
html {
    height: 100%;
    font-size: 20px;
}
body {
    height: 100%;
    width: 100%;
}
