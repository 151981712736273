.extended-view-command-bar {
    display: flex;
    padding-bottom: 1px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 16px;
    min-height: 44px;
}

.extended-view-command-bar__overflow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: hidden;
    flex-grow: 1;
}
