.bulk-tagging {
    height: 100%;
    min-width: 550px;
}

@media screen and (max-width: 600px) {
    .bulk-tagging {
        min-width: auto;
    }
}
