.app-panel {
    &__tab {
        display: grid;
        grid-auto-flow: column;
        align-items: end;
        align-content: center;
        -moz-column-gap: 5px;
             column-gap: 5px;
    }

    &__tab-body {
        height: 100%;
        display: none;
    }

    &__tab-body_active {
        display: block;
    }

    &__tab-close {
        font-size: 18px;
        display: grid;
    }
}

.settings-panel {
    padding: 18px 16px;
    display: grid;
    grid-template-rows: max-content auto;
    row-gap: 39px;

    &__header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    &__body {
        display: grid;
        grid-auto-flow: row;
        row-gap: 22.5px;
    }

    &__body-group {
        display: grid;
        grid-auto-flow: row;
        justify-content: left;
        row-gap: 34px;
    }
}
