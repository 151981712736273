.scenes {
    display: grid;
    row-gap: 16px;
}

.scenes__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}

.scenes__item {
    cursor: pointer;
    width: 83px;
    aspect-ratio: 16/9;
    display: block;
    background-size: cover;
    margin: 2px;
}

@media screen and (max-width: 600px) {
    .scenes__item {
        width: 45px;
    }
}

.scenes__canvas {
    display: none;
}
