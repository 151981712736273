.pagination {
    padding: 0 12px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 15px;
         column-gap: 15px;

    &__controlls {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        -moz-column-gap: 5px;
             column-gap: 5px;
    }

    &__page-size-selector {
        display: grid;
        grid-auto-flow: column;
        -moz-column-gap: 5px;
             column-gap: 5px;
        align-content: center;
        align-items: center;
        width: -moz-max-content;
        width: max-content;
    }
}
