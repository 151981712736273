.common-settings-section {
    display: grid;
    grid-auto-flow: row;
    row-gap: 16px;
}

.common-settings-fields {
    display: grid;
    grid-auto-flow: row;
    row-gap: 16px;
}
