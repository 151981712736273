.timeline {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content auto;
    gap: 8px;
}

.timeline__head {
    padding-right: 24px;
    text-align: right;
}

.timeline__list {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.timeline__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 16px;
    cursor: pointer;
}

.timeline__time {
    font-size: 10px;
    line-height: 14px;
    padding: 2px 4px;
    border-radius: 2px;
}

.timeline__text strong {
    font-weight: 600;
}

.timeline__group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 8px;
}

.timeline__label + .timeline__label::before {
    content: ", ";
}

.timeline__face {
    display: flex;
    gap: 8px;
    align-items: center;
}

.timeline__faces,
.timeline__keyframes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.timeline__keyframe {
    display: flex;
    gap: 8px;
    align-items: center;
}

.timeline__keyframe-img {
    height: 50px;
    aspect-ratio: 16/9;
    background-size: contain;
}
