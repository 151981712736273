.service-account {
    display: grid;
    grid-auto-flow: row;
    gap: 8px;
    justify-items: start;
}

.service-account__item {
    display: grid;
    grid-template-columns: max-content auto max-content;
    gap: 16px;
    padding: 16px 24px;
    align-items: center;
    width: 100%;
}
