.extended-view {
    display: grid;
    row-gap: 24px;
    -moz-column-gap: 44px;
         column-gap: 44px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "video info" "scenes info";
    height: 100%;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .extended-view {
        grid-template-areas: "video scenes" "info info";
        grid-template-rows: 1fr 5fr;
        row-gap: 16px;
        -moz-column-gap: 16px;
             column-gap: 16px;
    }
}

@media screen and (max-width: 600px) {
    .extended-view {
        grid-template-areas: "video" "scenes" "info";
        grid-template-columns: 1fr;
        grid-template-rows: min-content minmax(auto, 100px) 1fr;
    }
}

.extended-view__player {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.extended-view__video {
    grid-area: video;
}

@media screen and (max-width: 600px) {
    .extended-view__video {
        padding-right: 24px;
    }
}

.extended-view__scenes {
    grid-area: scenes;
    overflow: auto;
}

@media screen and (max-width: 768px) {
    .extended-view__scenes {
        overflow: auto;
    }
}

@media screen and (max-width: 600px) {
    .extended-view__scenes {
        padding-right: 24px;
        margin-right: 24px;
    }
}

.extended-view__info {
    grid-area: info;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    padding-right: 24px;
}

.extended-view__insights {
    display: grid;
    grid-auto-rows: min-content;
    margin-top: 24px;
    row-gap: 32px;
    overflow: auto;
    max-height: 100%;
    padding-right: 8px;
}

.extended-view__empty {
    margin-top: 24px;
}

.extended-view__section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: 100%;
    overflow: hidden;
}

.extended-view__section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.extended-view__section-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
    margin: -4px;
}

.extended-view__item {
    padding: 4px;
}

.extended-view__section-list_overflow {
    overflow: hidden;
    flex-wrap: nowrap;
}

.extended-view__more-icon {
    font-size: 14px;
}

.extended-view__more-tag {
    cursor: pointer;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    padding: 2px 6px;
    border-radius: 16px;
    display: flex;
    gap: 1px;
    align-items: center;
    margin-bottom: 8px;
    background: transparent;
}

.extended-view__more-people {
    cursor: pointer;
    font-weight: 600;
    background: transparent;
    vertical-align: middle;
    font-size: 12px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
}

.extended-view__group {
    display: grid;
    gap: 8px;
}

.extended-view__topics {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}
