.color-picker {
    display: flex;
    align-items: flex-end;
    gap: 16px;
}

.color-picker__preview {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: block;
    border: 1px solid #fff;
    cursor: pointer;
}

.color-picker__pallete {
    display: grid;
    height: 40px;
    grid-template-columns: auto;
    grid-auto-flow: column;
    border-radius: 4px;
    overflow: hidden;
}
