@use "../../styles/variables";

.header {
    height: variables.$headerHeight;
    align-content: center;
    display: grid;
    grid-template-columns: max-content auto max-content;
    -moz-column-gap: 20px;
         column-gap: 20px;
    align-items: center;
    justify-items: center;
    padding-left: 20px;
    padding-right: 12px;

    @media screen and (max-width: 600px) {
        grid-template-columns: auto max-content max-content;
        -moz-column-gap: 0px;
             column-gap: 0px;
    }

    &__logo {
        display: grid;
        grid-auto-flow: column;
        -moz-column-gap: 12px;
             column-gap: 12px;
        align-items: center;
        justify-self: start;
        grid-auto-rows: 40px;
    }

    &__logo-img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        min-width: 20px;
        min-width: 20px;
    }

    &__search {
        display: grid;
        align-items: center;
        justify-self: center;
        padding-right: 8px;
        position: relative;
        @media screen and (max-width: 600px) {
            padding-right: 0;
        }
    }

    &__actions {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        align-content: center;
        justify-self: end;
    }
}
