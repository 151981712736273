.seek-bar {
    max-width: 100%;
    padding: 1px;
}

.seek-bar__timeline {
    width: 100%;
    height: 8px;
    position: relative;
    display: block;
    cursor: pointer;
}

.seek-bar__time {
    height: 100%;
    display: block;
    position: absolute;
}

.seek-bar__progress {
    display: block;
    height: 5px;
    margin-bottom: 8px;
    max-width: 100%;
}

.file-details__personas:hover .seek-bar__progress,
.extended-view__section:hover .seek-bar__progress {
    background: #96c6fa;
}

.seek-bar__btns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    -moz-column-gap: 6px;
         column-gap: 6px;
}

.seek-bar__tooltip {
    display: block;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 0px;
}
