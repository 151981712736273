.file-tags {
    display: grid;
    gap: 16px;
}

.file-tags__group {
    display: grid;
    gap: 8px;
}

.file-tags__list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    overflow: hidden;
}
