.file-details {
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: max-content auto;
    row-gap: 8px;
}

.file-details__header {
    display: grid;
    row-gap: 8px;
}

.file-details__preview {
    overflow: hidden;
    aspect-ratio: 16/9;
    width: 100%;
}

.file-details__thumbnail {
    aspect-ratio: 16/9;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

@media screen and (max-width: 460px) {
    .file-details__btn-text {
        display: none;
    }
}

.file-details__body {
    overflow: auto;
    margin-right: -16px;
    padding-right: 16px;
}

.file-details__subtitle {
    font-weight: 600;
    opacity: 0.6;
}

.file-details__personas {
    display: grid;
    gap: 16px;
}

.file-details__personas-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.file-details__avatar {
    transition: color 0.3s ease;
    cursor: pointer;
}

.file-details__avatar + .file-details__avatar {
    margin-bottom: 10px;
}

.file-details__avatar:not(:last-child) {
    margin-right: 10px;
}
