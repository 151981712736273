.filters {
    display: grid;
    grid-auto-flow: row;
    row-gap: 26px;
    overflow: auto;
    grid-auto-rows: max-content;
    height: 100%;
    padding-right: 16px;
    padding-bottom: 16px;
    margin-right: -16px;

    &__group-container {
        display: grid;
        grid-auto-flow: row;
        row-gap: 16px;
        overflow: hidden;
    }

    &__items-container {
        display: grid;
        grid-auto-flow: row;
        margin: -8px 0;
    }

    &__checkbox {
        grid-template-columns: 32px calc(320px - 32px);
        grid-auto-flow: column;
        overflow-wrap: break-word;
        margin-left: -8px;
    }

    &__range {
        display: grid;
        row-gap: 8px;
        margin: 8px 0;
    }

    &__more {
        display: flex;
        justify-content: space-between;
    }
}
