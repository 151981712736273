.emotions {
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    padding-right: 5px;
}

.emotions::before {
    content: "";
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background: currentColor;
    display: inline-block;
    border-radius: 50%;
}
