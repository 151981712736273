@use "./variables.scss";
@import "./helpers/normalize.min.css";
@import "./helpers/reset.css";

$scrollbarColor: #b8b8b8;

@mixin scrollbar {
    width: 8px;
    height: 8px;
}

@mixin thumb {
    background-color: $scrollbarColor;
    border-radius: 3px;
}

@mixin thumbHover {
    background-color: darken($scrollbarColor, 10%);
}

@mixin thumbActive {
    background-color: darken($scrollbarColor, 20%);
}

@mixin track {
    display: none;
}

@mixin corner {
    background-color: transparent;
}

#root {
    height: 100dvh;
    overflow: hidden;
}

#authError {
    @extend #root;
    margin: auto auto;
    display: grid;
    align-items: center;
    justify-content: center;
}

.app-wrapper {
    height: 100dvh;
    overflow: hidden;
    position: relative;
}

.txt {
    &_muted {
        opacity: 0.6;
    }
    &_b-a {
        word-break: break-all;
    }
    &_no-margin {
        margin: 0;
    }
}

.layout-container {
    padding-left: 1px;
    padding-right: 11px;
}

.layout {
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: max-content auto max-content;
}

.layout__container {
    overflow: auto;
    scroll-behavior: smooth;
    padding: 12px 24px;
}

.layout__container:first-child {
    padding-top: 24px;
}

@media screen and (max-width: 600px) {
    .layout__container:first-child {
        padding-top: 12px;
    }
}

.layout__container:last-child {
    padding-bottom: 24px;
}

@media screen and (max-width: 600px) {
    .layout__container:last-child {
        padding-bottom: 12px;
    }
}

.btn {
    &_fit {
        width: -moz-fit-content;
        width: fit-content;
    }

    // use fluentui token variables if possible instead of this class
    &_danger {
        background-color: var(--colorPaletteRedBackground3) !important;

        &:hover {
            background-color: var(--colorPaletteRedForeground1) !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .hide-mobile {
        display: none;
    }
}

* {
    &::-webkit-scrollbar {
        @include scrollbar();
    }

    &::-moz-scrollbar {
        @include scrollbar();
    }

    &::-ms-scrollbar {
        @include scrollbar();
    }

    &::-webkit-scrollbar-thumb {
        @include thumb();
    }

    &::-moz-scrollbar-thumb {
        @include thumb();
    }

    &::-ms-scrollbar-thumb {
        @include thumb();
    }

    &::-webkit-scrollbar-thumb:hover {
        @include thumbHover();
    }

    &::-moz-scrollbar-thumb:hover {
        @include thumbHover();
    }

    &::-ms-scrollbar-thumb:hover {
        @include thumbHover();
    }

    &::-webkit-scrollbar-thumb:active {
        @include thumbActive();
    }

    &::-moz-scrollbar-thumb:active {
        @include thumbActive();
    }

    &::-ms-scrollbar-thumb:active {
        @include thumbActive();
    }

    &::-webkit-scrollbar-track {
        @include track();
    }

    &::-moz-scrollbar-track {
        @include track();
    }

    &::-ms-scrollbar-track {
        @include track();
    }

    &::-webkit-scrollbar-corner {
        @include corner();
    }

    &::-moz-scrollbar-corner {
        @include corner();
    }

    &::-ms-scrollbar-corner {
        @include corner();
    }
}

/* Chrome, Safari, Edge, Opera */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* Firefox */
input[type="search"]::-moz-search-decoration,
input[type="search"]::-moz-search-cancel-button,
input[type="search"]::-moz-search-results-button,
input[type="search"]::-moz-search-results-decoration {
    display: none;
}

/* Internet Explorer */
input::-ms-clear {
    display: none;
}

.item_v-centered {
    display: grid;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    grid-auto-flow: row;
    row-gap: 10px;
}

.image {
    &_table-cell {
        height: 20px;
        width: 20px;
        -o-object-fit: cover;
           object-fit: cover;
        margin: 0px;
    }

    &_no-pointer-events {
        pointer-events: none;
    }
}

.table {
    min-width: 600px;
    overflow: hidden;
}

.table-container {
    min-width: 1200px;
    width: 100%;
    position: relative;
}

.cell-layout {
    width: 100%;

    > div {
        width: inherit;
    }
}

.cursor {
    &_info {
        cursor: help;
    }
}

.app {
    &__layout {
        display: flex;

        @media screen and (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }

    &__navigation {
        display: grid;
        align-items: center;
        height: calc(100dvh - variables.$headerHeight);
        row-gap: 8px;
        justify-content: center;
        align-content: start;
        padding: 4px 8px;
        width: 60px;

        @media screen and (max-width: 600px) {
            width: 100%;
            height: variables.$navigationMobileHeight;
            padding: 0;
        }
    }

    &__main {
        display: grid;
        grid-template-rows: variables.$headerHeight auto;
        position: relative;
        box-shadow:
            0px 1.2px 3.6px rgba(0, 0, 0, 0.03),
            0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
        width: 100%;
        height: calc(100dvh - variables.$headerHeight);
        overflow: hidden;

        @media screen and (max-width: 600px) {
            height: calc(
                100dvh - variables.$headerHeight -
                    variables.$navigationMobileHeight
            );
            overflow: hidden;
        }
    }

    &__main-content {
        display: grid;
        grid-template-columns: 1fr auto;
        position: relative;
        height: 100%;
        overflow: hidden;
    }

    &_loading {
        height: 100%;

        &-abs {
            position: absolute;
            width: 100%;
        }
    }

    &__notification-toasts-container {
        position: absolute;
        width: 100%;
        overflow: hidden;
        z-index: 100;
        display: grid;
        grid-auto-flow: row;
        row-gap: 14px;
        align-items: end;
        align-content: end;
        padding: 24px;
        bottom: 0;
    }
}
