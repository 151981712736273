.edit-tags-panel {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    max-width: 930px;
    min-width: 550px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.edit-tags-panel_multiply {
    grid-template-columns: 1fr;
}

@media screen and (max-width: 600px) {
    .edit-tags-panel {
        width: auto;
        min-width: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 60% 40%;
        grid-auto-flow: row;
        gap: 16px;
    }

    .edit-tags-panel_multiply {
        grid-template-rows: auto;
    }
}

.edit-tags-panel__info {
    display: grid;
    grid-template-rows: max-content auto;
    gap: 16px;
    height: 100%;
    overflow: hidden;
    flex-shrink: 1;
}

@media screen and (max-width: 600px) {
    .edit-tags-panel__info {
        grid-template-rows: max-content max-content;
        overflow: auto;
        grid-row-start: 2;
    }
}

.edit-tags-panel__form {
    overflow: hidden;
    height: 100%;
}

@media screen and (max-width: 600px) {
    .edit-tags-panel__form {
        grid-row-start: 1;
    }
}

.edit-tags-panel__accordion {
    overflow: auto;
}
