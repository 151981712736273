.image-field {
    position: relative;
    display: grid;
    gap: 8px;
}

.image-field__label {
    display: grid;
    grid-template-columns: max-content auto max-content;
    align-items: center;
    gap: 40px;
}

.image-field__img {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-field__info {
    display: grid;
    gap: 4px;
}

.image-field__icon {
    width: 48px;
    height: 48px;
}

.image-field__input {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}
