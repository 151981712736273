.download-locations__list {
    display: grid;
    gap: 20px;
    grid-auto-flow: row;
    max-width: 650px;
}

.download-locations__item {
    display: grid;
    -moz-column-gap: 32px;
         column-gap: 32px;
    grid-template-columns: auto max-content;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}

.download-locations__item-info {
    display: grid;
    gap: 8px;
    grid-auto-flow: row;
}
