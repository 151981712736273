@use "../../../../styles/variables.scss";

.file-search {
    &__btn-toggle {
        display: none;

        @media screen and (max-width: 600px) {
            display: block;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: auto max-content;
        align-items: center;
        overflow: hidden;
        transition: width 0.2s ease;

        @media screen and (max-width: 600px) {
            position: fixed;
            height: variables.$headerHeight;
            padding: 0 20px;
            right: 0;
            left: 0;
            top: 0;
            z-index: 100;

            &_hidden {
                width: 0;
                padding: 0;
            }
        }
    }
}
