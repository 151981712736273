.tiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, 170px);
    grid-template-rows: repeat(auto-fill, 170px);
    grid-gap: 10px;
    padding: 10px 0;
}

@media screen and (max-width: 600px) {
    .tiles {
        grid-template-columns: repeat(auto-fill, 120px);
        grid-template-rows: repeat(auto-fill, 120px);
        justify-content: space-around;
    }
}
