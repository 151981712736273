.dialog-details {
    max-height: 200px;
    overflow-x: hidden;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    row-gap: 6px;
    overflow-y: auto;
    overflow-wrap: anywhere;
}

.parsed-url {
    display: grid;
}
