.jobs-table__title > div > span {
    display: grid;
    grid-template-columns: auto max-content;
    -moz-column-gap: 24px;
         column-gap: 24px;
    align-items: center;
    justify-content: space-between;
}

.jobs__header {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: space-between;
    align-items: center;
}

.jobs__sync-txt {
    height: -moz-fit-content;
    height: fit-content;
}

.jobs__message-container {
    cursor: help;
}

.jobs__message {
    display: block !important;
    overflow: hidden !important;
    text-wrap: nowrap !important;
    text-overflow: ellipsis !important;
}

.jobs__status-cell {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    row-gap: 6px;
    padding: 6px 0;
    grid-template-columns: 284px;
}

.jobs__prgogress-info {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    row-gap: 4px;
}
