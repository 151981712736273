.tile-preview {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 3px 1px rgba(1, 1, 0, 0.05);
    transition: opacity 0.2s linear;

    &:hover {
        outline: 1px solid #c8c6c4;
        box-shadow: 0 1px 3px 1px rgba(1, 1, 0, 0.05);
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(
            360deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 43.86%
        );
    }

    &__link {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__link:hover &__title {
        text-decoration: underline;
    }
}
