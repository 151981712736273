.file-information {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-flow: row;
    row-gap: 12px;
    -moz-column-gap: 12px;
         column-gap: 12px;
}

@media screen and (max-width: 900px) {
    .edit-tags-panel {
        grid-template-columns: auto auto auto;
    }
}

.file-information__item {
    display: grid;
    grid-template-rows: 20px auto;
    word-break: break-all;
    row-gap: 2px;
}

.file-information__item_full-width {
    grid-column-start: 1;
    grid-column-end: 4;
}
@media screen and (max-width: 900px) {
    .file-information__item_full-width {
        grid-column-end: 3;
    }
}
.file-information__header {
    font-weight: 600;
    font-size: 12px;
    opacity: 0.8;
}
