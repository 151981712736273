.user-settings {
    display: grid;
    grid-auto-flow: row;
    row-gap: 22.5px;

    &__group {
        display: grid;
        grid-auto-flow: row;
        justify-content: left;
        row-gap: 34px;
    }
}
